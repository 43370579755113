/* Outer container that adds scrollbar */
.scroll-container {
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888888 #333; /* Custom scrollbar color for Firefox */
    background-color: #121212; /* Black background */
    color: white; /* White text */
  }
  
  /* Custom scrollbar styles for Webkit-based browsers (e.g., Chrome, Edge) */
  .scroll-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .scroll-container::-webkit-scrollbar-track {
    background: #333; /* Track background */
  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 5px; /* Rounded scrollbar */
  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb hover color */
  }
  
  /* Content styles */
  .content {
    padding: 20px;
  }
  
  .graph {
    background: #222; /* Gray background for graphs */
    margin: 10px 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  