.chat-container {
    width: 400px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #090909;
  }
  
  .messages-container {
    height: 300px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #090808;
    margin-bottom: 10px;
  }
  
  .message {
    padding: 5px 10px;
    margin-bottom: 5px;
    background-color: #f4efef;
    border-radius: 3px;
    color: black;
  }
  
  .input-container {
    display: flex;
    gap: 10px;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 8px;
    border-radius: 3px;
    border: 1px solid #ddd;
  }
  
  button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 3px;
  }
  
  button:hover {
    background-color: #0056b3;
  }  