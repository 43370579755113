/* EmotionButtons.css */
.emotion-buttons {
    display: flex;
    justify-content: center;
  }
  
  .emotion-buttons button {
    margin-right: 20px; /* Add spacing between buttons */
    font-size: 24px; /* Set the font size */
    width: 50px; /* Set the width */
    height: 50px; /* Set the height */
    justify-content: center;
  }
  
  /* Define styles for each button */
  .emotion-buttons button.sad {
    background-color: #f0a3a3; /* Customize color for sad emoji */
  }
  
  .emotion-buttons button.average {
    background-color: #f0e68c; /* Customize color for average emoji */
  }
  
  .emotion-buttons button.happy {
    background-color: #add8e6; /* Customize color for happy emoji */
  }
  