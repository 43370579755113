/* Reset styles (normalize.css) */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #121212; /* Dark background color for dark theme */
    color: #FFFFFF; /* White text for dark theme */
    font-size: 16px;
    line-height: 1.5;
  }
  
  a {
    text-decoration: none;
    color: #1E90FF; /* DodgerBlue */
  }
  
  a:hover {
    color: #6495ED; /* CornflowerBlue */
  }
  
  ul {
    list-style: none;
  }
  
  button {
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #1E90FF;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #6495ED;
  }
  
  button:disabled {
    background-color: #A9A9A9; /* DarkGray */
    cursor: not-allowed;
  }
  
  input, select, textarea {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #A9A9A9; /* DarkGray */
    border-radius: 5px;
    background-color: #121212;
    color: #FFFFFF;
    font-size: 16px;
  }
  
  input:focus, select:focus, textarea:focus {
    outline: none;
    border-color: #1E90FF;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  h2 {
    font-size: 2rem;
  }
  
  h3 {
    font-size: 1.75rem;
  }
  
  h4 {
    font-size: 1.5rem;
  }
  
  h5 {
    font-size: 1.25rem;
  }
  
  h6 {
    font-size: 1rem;
  }
  
  /* Utility classes */
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-left {
    text-align: left;
  }
  
  .mt-1 {
    margin-top: 10px;
  }
  
  .mt-2 {
    margin-top: 20px;
  }
  
  .mb-1 {
    margin-bottom: 10px;
  }
  
  .mb-2 {
    margin-bottom: 20px;
  }
  
  .p-1 {
    padding: 10px;
  }
  
  .p-2 {
    padding: 20px;
  }
  
  .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .card {
    background-color: #1c1c1c;
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1c1c1c;
    border-bottom: 2px solid #A9A9A9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .header-button {
    background: none;
    border: none;
    color: #FFFFFF;
    font-size: 24px;
    cursor: pointer;
  }
  
  .header-button:hover {
    color: #1E90FF;
  }
  
  .navigation-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #1c1c1c;
    border-top: 2px solid #A9A9A9;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .nav-button {
    background: none;
    border: none;
    color: #A9A9A9; /* Inactive color */
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .nav-button.active {
    color: #1E90FF; /* Active color */
  }
  
  .nav-button:hover {
    color: #FFFFFF;
  }
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f4f4f4; /* Light gray background for visibility */
    border-radius: 8px;
}

.list-content {
    flex: 1; /* Takes up the maximum width minus button width */
}

.edit-button {
    padding: 5px 10px;
    background-color: #1E90FF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  