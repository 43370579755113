/* styles.css */
.bottom-tab-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #f0f0f0;
    padding: 10px 0;
  }
  
  .bottom-tab-nav a {
    text-decoration: none;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .bottom-tab-nav a:hover {
    background-color: #ccc;
  }
  