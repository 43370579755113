/* LandingPage.css */
.landing-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container takes up at least the full viewport height */
}

.content {
  flex-grow: 1; /* Allow the content to grow and fill the remaining space */
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff; /* Set the background color */
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Add a shadow to the bottom */
}

.bottom-navigation nav ul {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}

.bottom-navigation nav ul li {
  padding: 10px;
}

.bottom-navigation nav ul li a {
  text-decoration: none;
  color: #333; /* Set the link color */
}