/* styles.css */
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .header {
    background-color: #ccc;
    padding: 10px;
    justify-content: center; 
    align-items: center;
    text-align: center;
  }
  
  .content {
    flex: 1; /* Take up remaining space */
    padding: 10px;
  }
  
  .footer {
    background-color: #ccc;
    padding: 10px;
    margin-top: auto;
    margin-bottom: 40px;
    text-align: center;
  }
  